import axios from 'axios';
import config from '../config';

const instance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    Accept: 'multipart/form-data',
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  function (config) {
    const loader = document.querySelector('.loading');
    loader.style.display = 'block';

    const token = sessionStorage.getItem('__ADMIN_UX_JOB_SITE_ACCESS_TOKEN__');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    const loader = document.querySelector('.loading');
    if (loader) {
      loader.style.display = 'none';
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    const loader = document.querySelector('.loading');
    if (loader) {
      loader.style.display = 'none';
    }

    if (error.response && error.response.status === 401 && !originalRequest.isRetry) {
      originalRequest.isRetry = true;

      try {
        const refreshToken = localStorage.getItem('__ADMIN_UX_JOB_SITE_REFRESH_TOKEN__');
        const { data } = await axios.post(
          `${config.apiUrl}/auth/refresh-token`,
          { refresh_token: refreshToken },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('__ADMIN_UX_JOB_SITE_ACCESS_TOKEN__')}`
            }
          }
        );

        sessionStorage.setItem('__ADMIN_UX_JOB_SITE_ACCESS_TOKEN__', data.accessToken);
        localStorage.setItem('__ADMIN_UX_JOB_SITE_REFRESH_TOKEN__', data.refreshToken);

        originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;

        return instance(originalRequest);
      } catch (err) {
        console.error('Token refresh failed:', err);
        if (error.response.status === 401 && !window.location.href.includes('login')) {
          window.location.href = '/login';
        }
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
