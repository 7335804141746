import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import API_UTILS from '../../../api';
import { useLocation } from 'react-router-dom';

const Employer = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API_UTILS.getEmployerById(location.state.userData.id);
        setData(res.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <MainCard title="General Details">
        <Typography variant="body2">Loading...</Typography>
      </MainCard>
    );
  }

  return (
    <MainCard title="Employer Details">
      {data ? (
        <div>
          {data?.logo && (
            <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
              <h3 style={{ margin: '0' }}>{data?.logo ? 'Profile Photo' : 'Logo'}: </h3>
              <img src={`${process.env.REACT_APP_PHOTO_URL}/${data?.logo}`} alt="avatar" width="100" height="100" />
            </div>
          )}
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Full Name: </h3>
            <span>{data.name}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Email: </h3>
            <span>{data.email}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Phone: </h3>
            <span>{data.mobile_no}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Company Name: </h3>
            <span>{data.company_name}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Company Website Link: </h3>
            <span>
              <a href={data.website} target="__blank" style={{ color: '#364152', fontWeight: 'bold' }}>
                {data.website}
              </a>
            </span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Company Location: </h3>
            <span>{data?.country ? data?.country : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>About the Company: </h3>
            <span>{data?.description ? data?.description : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Designation: </h3>
            <span>{data?.designation ? data?.designation.name : '-'}</span>
          </div>
        </div>
      ) : (
        'No Data'
      )}
    </MainCard>
  );
};

export default Employer;
