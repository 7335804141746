import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import API_UTILS from '../../../api';
import { useLocation } from 'react-router-dom';

const Job = () => {
  const [locationArray, setLocationArray] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API_UTILS.getSingleJob(location.state.userData.id);
        setData(res.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data && data.location) {
      convertJobLocation();
    }
  }, [data]);

  function convertJobLocation() {
    const locations = data.location.split(',');
    const jobLocationArray = [];

    for (let i = 0; i < locations.length; i += 2) {
      if (i + 1 < locations.length) {
        const firstLocation = locations[i].trim().replace(/^['"]/, '').replace(/['"]$/, '');
        const secondLocation = locations[i + 1].trim().replace(/^['"]/, '').replace(/['"]$/, '');

        jobLocationArray.push(`${firstLocation}, ${secondLocation}`);
      }
    }

    setLocationArray(jobLocationArray);
  }
  if (loading) {
    return (
      <MainCard title="General Details">
        <Typography variant="body2">Loading...</Typography>
      </MainCard>
    );
  }

  return (
    <MainCard title="Job Details">
      {data ? (
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Job Title: </h3>
            <span>{data.job_title}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Job Experience: </h3>
            <span>{data.job_experience}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Work Place Type: </h3>
            <span>{data.work_place_type}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Location: </h3>
            <ul style={{ margin: '0', paddingLeft: '25px' }}>
              {locationArray.map((loc, index) => (
                <li key={index}>{loc}</li>
              ))}
            </ul>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Salary Range: </h3>
            <span>{`${data.currency?.symbol} ${data.minimum_pay} - ${data.currency?.symbol} ${data.maximum_pay}`}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Apply By: </h3>
            <span>{data.apply_by.name}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Apply Text: </h3>
            <span>{data.apply_text}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Job Status: </h3>
            <span>{data.job_status}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Category: </h3>
            <span>{data.category.name}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Job Type: </h3>
            <span>{data.job_type.name}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Pay Type: </h3>
            <span>{data.pay_type.name}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Description: </h3>
            <span dangerouslySetInnerHTML={{ __html: data.description }} className="p_space"></span>
          </div>
          <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Skills: </h3>
            <ul style={{ margin: '0', paddingLeft: '25px' }}>
              {data?.skills && data.skills.length > 0 && data.skills.map((skill, index) => <li key={index}>{skill.name}</li>)}
            </ul>
          </div>
        </div>
      ) : (
        'No Data'
      )}
    </MainCard>
  );
};

export default Job;
