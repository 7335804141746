import React, { useState } from 'react';
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  useMediaQuery,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Box } from '@mui/system';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import API_UTILS from '../../../api';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Enter Password')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
  confirm_password: yup
    .string()
    .required('Re-Password is required')
    .oneOf([yup.ref('password')], 'Passwords must match')
});

const ChangePassword = ({ ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        password: values.password
      };
      const res = await API_UTILS.changePassword({ ...data });

      if (res.status === 201) {
        sessionStorage.removeItem('__ADMIN_UX_JOB_SITE_ACCESS_TOKEN__');
        localStorage.removeItem('__ADMIN_UX_JOB_SITE_REFRESH_TOKEN__');
        navigate('/login');
        localStorage.removeItem('admin_email');
        toast.success('Successfully Changed!', { position: 'top-right', type: 'success' });
        resetForm();
      } else {
        toast.error('Something went wrong!', { position: 'top-right', type: 'error' });
      }
    }
  });

  return (
    <MainCard title="Change Password">
      <form noValidate onSubmit={formik.handleSubmit} {...others}>
        <Grid container spacing={matchDownSM ? 0 : 2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.password && formik.errors.password)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
                inputProps={{}}
              />
              {formik.touched.password && formik.errors.password && (
                <FormHelperText error id="standard-weight-helper-text-password">
                  {formik.errors.password}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.confirm_password && formik.errors.confirm_password)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={formik.values.confirm_password}
                name="confirm_password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
                inputProps={{}}
              />
              {formik.touched.confirm_password && formik.errors.confirm_password && (
                <FormHelperText error id="standard-weight-helper-text-confirm-password">
                  {formik.errors.confirm_password}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          <AnimateButton>
            <Button
              disableElevation
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Change Password
            </Button>
          </AnimateButton>
        </Box>
      </form>
    </MainCard>
  );
};

export default ChangePassword;
