// material-ui

// import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return <img src={logo} alt="logo" width="180" />;
};

export default Logo;
