import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ProtectedRoute from 'ui-component/protectedRoutes';
import JobSeeker from 'views/pages/jobSeeker/jobSeeker';
import Employer from 'views/pages/employer/employer';
import ChangePassword from 'views/pages/accountSetting/changePassword';
import Job from 'views/pages/job/jobs';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

//page routing
const CurrencyList = Loadable(lazy(() => import('views/pages/currency/currencyList')));
const CurrencyForm = Loadable(lazy(() => import('views/pages/currency/currencyForm')));
const PayTypeList = Loadable(lazy(() => import('views/pages/payType/payTypeList')));
const PayTypeForm = Loadable(lazy(() => import('views/pages/payType/payTypeForm')));
const JobTypeList = Loadable(lazy(() => import('views/pages/jobType/jobTypeList')));
const JobTypeForm = Loadable(lazy(() => import('views/pages/jobType/jobTypeForm')));
const ApplyByList = Loadable(lazy(() => import('views/pages/applyBy/applyByList')));
const ApplyByForm = Loadable(lazy(() => import('views/pages/applyBy/applyByForm')));
const CategoryList = Loadable(lazy(() => import('views/pages/category/categoryList')));
const CategoryForm = Loadable(lazy(() => import('views/pages/category/categoryForm')));
const JobList = Loadable(lazy(() => import('views/pages/job/jobList')));
const RecommendedJobList = Loadable(lazy(() => import('views/pages/job/recommendedJobList')));
const FaqForm = Loadable(lazy(() => import('views/pages/faq')));
const FaqList = Loadable(lazy(() => import('views/pages/faq/faqList')));
const BlogForm = Loadable(lazy(() => import('views/pages/blog/blogForm')));
const BlogListForm = Loadable(lazy(() => import('views/pages/blog/blogList')));
const TagForm = Loadable(lazy(() => import('views/pages/tags/tagForm')));
const TagList = Loadable(lazy(() => import('views/pages/tags/tagList')));
const JobSeekerList = Loadable(lazy(() => import('views/pages/jobSeeker/jobSeekerList')));
const EmployerList = Loadable(lazy(() => import('views/pages/employer/employerList')));
const ContactList = Loadable(lazy(() => import('views/pages/contact/contactList')));
const SubscribeList = Loadable(lazy(() => import('views/pages/subscribe/subscribeList')));
const CurrentCompanyForm = Loadable(lazy(() => import('views/pages/currentCompany/currentCompanyForm')));
const CurrentCompanyList = Loadable(lazy(() => import('views/pages/currentCompany/currentCompanyList')));
const DegreeForm = Loadable(lazy(() => import('views/pages/degree/degreeForm')));
const DegreeList = Loadable(lazy(() => import('views/pages/degree/degreeList')));
const DesignationForm = Loadable(lazy(() => import('views/pages/designation/designationForm')));
const DesignationList = Loadable(lazy(() => import('views/pages/designation/designationList')));
const FieldOfStudyForm = Loadable(lazy(() => import('views/pages/fieldOfStudy/fieldOfStudyForm')));
const FieldOfStudyList = Loadable(lazy(() => import('views/pages/fieldOfStudy/fieldOfStudyList')));
const EducationInstituteForm = Loadable(lazy(() => import('views/pages/educationInstitute/educationInstituteForm')));
const EducationInstituteList = Loadable(lazy(() => import('views/pages/educationInstitute/educationInstituteList')));
const JobStatusForm = Loadable(lazy(() => import('views/pages/job/jobStatus')));
const JobApprovedForm = Loadable(lazy(() => import('views/pages/job/jobApproved')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />
    },
    {
      path: 'account-setting',
      children: [
        {
          path: '',
          element: <ChangePassword />
        }
      ]
    },
    {
      path: 'dashboard',
      children: [
        {
          path: '',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'currency',
      children: [
        {
          path: 'currency-list',
          element: <CurrencyList />
        },
        {
          path: 'currency-form',
          element: <CurrencyForm />
        },
        {
          path: 'currency-form/edit/:id',
          element: <CurrencyForm />
        }
      ]
    },
    {
      path: 'apply-by',
      children: [
        {
          path: 'apply-by-list',
          element: <ApplyByList />
        },
        {
          path: 'apply-by-form',
          element: <ApplyByForm />
        },
        {
          path: 'apply-by-form/edit/:id',
          element: <ApplyByForm />
        }
      ]
    },
    {
      path: 'pay-type',
      children: [
        {
          path: 'pay-type-list',
          element: <PayTypeList />
        },
        {
          path: 'pay-type-form',
          element: <PayTypeForm />
        },
        {
          path: 'pay-type-form/edit/:id',
          element: <PayTypeForm />
        }
      ]
    },
    {
      path: 'job-type',
      children: [
        {
          path: 'job-type-list',
          element: <JobTypeList />
        },
        {
          path: 'job-type-form',
          element: <JobTypeForm />
        },
        {
          path: 'job-type-form/edit/:id',
          element: <JobTypeForm />
        }
      ]
    },
    {
      path: 'category',
      children: [
        {
          path: 'category-list',
          element: <CategoryList />
        },
        {
          path: 'category-form',
          element: <CategoryForm />
        },
        {
          path: 'category-form/edit/:id',
          element: <CategoryForm />
        }
      ]
    },
    {
      path: 'job',
      children: [
        {
          path: 'job-list/view/:id',
          element: <Job />
        },
        {
          path: 'job-list',
          element: <JobList />
        },
        {
          path: 'recommended-job-list',
          element: <RecommendedJobList />
        },
        {
          path: 'job-status-form/edit/:id',
          element: <JobStatusForm />
        },
        {
          path: 'job-approved-form/edit/:id',
          element: <JobApprovedForm />
        }
      ]
    },
    {
      path: 'faq',
      children: [
        {
          path: 'faq-form',
          element: <FaqForm />
        },
        {
          path: 'faq-list',
          element: <FaqList />
        },
        {
          path: 'faq-form/edit/:id',
          element: <FaqForm />
        }
      ]
    },
    {
      path: 'blog',
      children: [
        {
          path: 'blog-form',
          element: <BlogForm />
        },
        {
          path: 'blog-list',
          element: <BlogListForm />
        },
        {
          path: 'blog-form/edit/:id',
          element: <BlogForm />
        }
      ]
    },
    {
      path: 'current-company',
      children: [
        {
          path: 'current-company-form',
          element: <CurrentCompanyForm />
        },
        {
          path: 'current-company-list',
          element: <CurrentCompanyList />
        },
        {
          path: 'current-company-form/edit/:id',
          element: <CurrentCompanyForm />
        }
      ]
    },
    {
      path: 'degree',
      children: [
        {
          path: 'degree-form',
          element: <DegreeForm />
        },
        {
          path: 'degree-list',
          element: <DegreeList />
        },
        {
          path: 'degree-form/edit/:id',
          element: <DegreeForm />
        }
      ]
    },
    {
      path: 'designation',
      children: [
        {
          path: 'designation-form',
          element: <DesignationForm />
        },
        {
          path: 'designation-list',
          element: <DesignationList />
        },
        {
          path: 'designation-form/edit/:id',
          element: <DesignationForm />
        }
      ]
    },
    {
      path: 'field-of-study',
      children: [
        {
          path: 'field-of-study-form',
          element: <FieldOfStudyForm />
        },
        {
          path: 'field-of-study-list',
          element: <FieldOfStudyList />
        },
        {
          path: 'field-of-study-form/edit/:id',
          element: <FieldOfStudyForm />
        }
      ]
    },
    {
      path: 'education-institute',
      children: [
        {
          path: 'education-institute-form',
          element: <EducationInstituteForm />
        },
        {
          path: 'education-institute-list',
          element: <EducationInstituteList />
        },
        {
          path: 'education-institute-form/edit/:id',
          element: <EducationInstituteForm />
        }
      ]
    },
    {
      path: 'skill',
      children: [
        {
          path: 'skill-form',
          element: <TagForm />
        },
        {
          path: 'skill-list',
          element: <TagList />
        },
        {
          path: 'skill-form/edit/:id',
          element: <TagForm />
        }
      ]
    },
    {
      path: 'job-seeker',
      children: [
        {
          path: 'job-seeker-list',
          element: <JobSeekerList />
        },
        {
          path: 'job-seeker-list/view/:id',
          element: <JobSeeker />
        }
      ]
    },
    {
      path: 'employer',
      children: [
        {
          path: 'employer-list',
          element: <EmployerList />
        },
        {
          path: 'employer-list/view/:id',
          element: <Employer />
        }
      ]
    },
    {
      path: 'subscribe',
      children: [
        {
          path: 'subscribe-list',
          element: <SubscribeList />
        }
      ]
    },
    {
      path: 'contact',
      children: [
        {
          path: 'contact-list',
          element: <ContactList />
        }
      ]
    }
  ]
};

export default MainRoutes;
