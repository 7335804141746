import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import API_UTILS from '../../../api';
import { useLocation } from 'react-router-dom';

const JobSeeker = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API_UTILS.getUserById(location.state.userData.id);
        setData(res.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <MainCard title="General Details">
        <Typography variant="body2">Loading...</Typography>
      </MainCard>
    );
  }

  const downloadResume = async (resume, e) => {
    e.stopPropagation();
    if (resume) {
      try {
        const response = await fetch(`${process.env.REACT_APP_PHOTO_URL}/${resume}`);
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = url;

        tempLink.setAttribute('download', resume.split('/').pop() || 'resume.pdf');

        document.body.appendChild(tempLink);
        tempLink.click();

        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading the resume:', error);
      }
    } else {
      console.error('Resume not found');
    }
  };
  const transformToArray = (value) => (value ? value.split(',').map((item) => item.trim().replace(/^'|'$/g, '')) : []);

  const openForRoleArray = transformToArray(typeof data?.open_for_role === 'string' ? data?.open_for_role : '');
  const preferredJobTypeArray = transformToArray(typeof data?.preferred_job_type === 'string' ? data?.preferred_job_type : '');
  const preferredWorkPlaceTypeArray = transformToArray(typeof data?.preferred_wrok_place === 'string' ? data?.preferred_wrok_place : '');
  const relocatePreferredLocationArray =
    data?.relocate_preferred_location && data?.relocate_preferred_location.split("','").map((location) => location.replace(/'/g, ''));

  return (
    <MainCard title="Job-Seeker Details">
      {data ? (
        <div style={{ position: 'relative' }}>
          {data?.profile_photo && (
            <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
              <h3 style={{ margin: '0' }}>{data?.profile_photo ? 'Profile Photo' : 'Logo'}: </h3>
              <img src={`${process.env.REACT_APP_PHOTO_URL}/${data?.profile_photo}`} alt="avatar" width="100" height="100" />
            </div>
          )}
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Full Name: </h3>
            <span>{data.name}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Email: </h3>
            <span>{data.email}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Phone: </h3>
            <span>{data.mobile_no}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Job Title: </h3>
            <span>{data.job_title}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Location: </h3>
            <span>{data.location}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Gender: </h3>
            <span>{data?.gender ? data?.gender : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Pronouns: </h3>
            <span>{data?.pronouns ? data?.pronouns : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Open for Role: </h3>
            {openForRoleArray && openForRoleArray.length > 0 ? (
              <ul style={{ margin: 0, paddingLeft: 25 }}>
                {openForRoleArray.map((role, index) => (
                  <li key={index}>{role}</li>
                ))}
              </ul>
            ) : (
              '-'
            )}
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Portfolio Link: </h3>
            <span>
              {data?.portfolio_link ? (
                <a href={data.portfolio_link} target="__blank" style={{ color: '#364152', fontWeight: 'bold' }}>
                  {data.portfolio_link}
                </a>
              ) : (
                '-'
              )}
            </span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Bio: </h3>
            <span>{data?.bio ? data?.bio : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: 0 }}>Resume:</h3>
            {data.resume ? (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  downloadResume(data.resume, e);
                }}
                style={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
                role="link"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    downloadResume(data.resume, e);
                  }
                }}
              >
                Download Resume
              </span>
            ) : (
              '-'
            )}
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Total Years of Experience: </h3>
            <span>{data?.total_experience ? `${data?.total_experience} Years` : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Relevant Experience: </h3>
            <span>{data?.relevant_experience ? `${data?.relevant_experience} Years` : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Current Company: </h3>
            <span>{data?.current_company?.name ? data?.current_company?.name : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Current Job Title: </h3>
            <span>{data?.current_job_title ? data?.current_job_title : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Current Start Date: </h3>
            <span>{data?.current_job_start_date ? data?.current_job_start_date : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Current End Date: </h3>
            <span>{data?.current_job_end_date ? data?.current_job_end_date : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Education Institute: </h3>
            <span>{data?.education_institute ? data?.education_institute?.name : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Graduation Year: </h3>
            <span>{data?.graduation_year ? data?.graduation_year : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Degree: </h3>
            <span>{data?.degree ? data?.degree?.name : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>GPA: </h3>
            <span>{data?.gpa ? data?.gpa : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Skills: </h3>
            {data?.skills && data?.skills.length > 0 ? (
              <ul style={{ margin: 0, paddingLeft: 25 }}>
                {data?.skills.map((role, index) => (
                  <li key={index}>{role.name}</li>
                ))}
              </ul>
            ) : (
              '-'
            )}
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Website: </h3>
            <span>
              {data?.website ? (
                <a href={data.website} target="__blank" style={{ color: '#364152', fontWeight: 'bold' }}>
                  {data.website}
                </a>
              ) : (
                '-'
              )}
            </span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>LinkedIn: </h3>
            <span>
              {data?.linkedin ? (
                <a href={data.linkedin} target="__blank" style={{ color: '#364152', fontWeight: 'bold' }}>
                  {data.linkedin}
                </a>
              ) : (
                '-'
              )}
            </span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Preferred Job Type: </h3>
            {preferredJobTypeArray && preferredJobTypeArray.length > 0 ? (
              <ul style={{ margin: 0, paddingLeft: 25 }}>
                {preferredJobTypeArray.map((role, index) => (
                  <li key={index}>{role}</li>
                ))}
              </ul>
            ) : (
              '-'
            )}
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Preferred Job Type: </h3>
            {preferredWorkPlaceTypeArray && preferredWorkPlaceTypeArray.length > 0 ? (
              <ul style={{ margin: 0, paddingLeft: 25 }}>
                {preferredWorkPlaceTypeArray.map((role, index) => (
                  <li key={index}>{role}</li>
                ))}
              </ul>
            ) : (
              '-'
            )}
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Ready to Relocate Location: </h3>
            {relocatePreferredLocationArray && relocatePreferredLocationArray.length > 0 ? (
              <ul style={{ margin: 0, paddingLeft: 25 }}>
                {relocatePreferredLocationArray.map((role, index) => (
                  <li key={index}>{role}</li>
                ))}
              </ul>
            ) : (
              '-'
            )}
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Salary Currency: </h3>
            <span>{data?.current_salary_currency ? data?.current_salary_currency : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Fixed Amount: </h3>
            <span>{data?.current_salary_fixed_amount ? data?.current_salary_fixed_amount : '-'}</span>
          </div>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <h3 style={{ margin: '0' }}>Variable Amount: </h3>
            <span>{data?.current_salary_variable_amount ? data?.current_salary_variable_amount : '-'}</span>
          </div>
        </div>
      ) : (
        'No Data'
      )}
    </MainCard>
  );
};

export default JobSeeker;
