// assets
import { IconKey } from '@tabler/icons-react';

// constant
const icons = {
  IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Pages',
  type: 'group',
  children: [
    {
      id: 'collapse',
      title: 'Category',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'category_list',
          title: 'Category List',
          type: 'item',
          url: '/category/category-list',
          target: false
        },
        {
          id: 'category_form',
          title: 'Add Category',
          type: 'item',
          url: '/category/category-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Currency',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'currency-list',
          title: 'Currency List',
          type: 'item',
          url: '/currency/currency-list',
          target: false
        },
        {
          id: 'currency-form',
          title: 'Add Currency',
          type: 'item',
          url: '/currency/currency-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Pay-Type',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'pay-type',
          title: 'Pay-Type List',
          type: 'item',
          url: '/pay-type/pay-type-list',
          target: false
        },
        {
          id: 'pay-type-form',
          title: 'Add Pay-Type',
          type: 'item',
          url: '/pay-type/pay-type-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Job-Type',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'job-type',
          title: 'Job-Type List',
          type: 'item',
          url: '/job-type/job-type-list',
          target: false
        },
        {
          id: 'job-type-form',
          title: 'Add Job-Type',
          type: 'item',
          url: '/job-type/job-type-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Apply By',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'apply-by',
          title: 'Apply By List',
          type: 'item',
          url: '/apply-by/apply-by-list',
          target: false
        },
        {
          id: 'apply-by-form',
          title: 'Add Apply By',
          type: 'item',
          url: '/apply-by/apply-by-form',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Current Company',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'current-company-form',
          title: 'Add Current Company',
          type: 'item',
          url: '/current-company/current-company-form',
          target: false
        },
        {
          id: 'current-company-list',
          title: 'Current Company List',
          type: 'item',
          url: '/current-company/current-company-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Degree',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'degree-form',
          title: 'Add Degree',
          type: 'item',
          url: '/degree/degree-form',
          target: false
        },
        {
          id: 'degree-list',
          title: 'Degree List',
          type: 'item',
          url: '/degree/degree-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Designation',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'designation-form',
          title: 'Add Designation',
          type: 'item',
          url: '/designation/designation-form',
          target: false
        },
        {
          id: 'designation-list',
          title: 'Designation List',
          type: 'item',
          url: '/designation/designation-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Field of Study',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'field-of-study-form',
          title: 'Add Field of Study',
          type: 'item',
          url: '/field-of-study/field-of-study-form',
          target: false
        },
        {
          id: 'field-of-study-list',
          title: 'Field of Study List',
          type: 'item',
          url: '/field-of-study/field-of-study-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Education Institute',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'education-institute-form',
          title: 'Add Education Institute',
          type: 'item',
          url: '/education-institute/education-institute-form',
          target: false
        },
        {
          id: 'education-institute-list',
          title: 'Education Institute List',
          type: 'item',
          url: '/education-institute/education-institute-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Skills',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'skill-form',
          title: 'Add Skill',
          type: 'item',
          url: '/skill/skill-form',
          target: false
        },
        {
          id: 'skill-list',
          title: 'Skills List',
          type: 'item',
          url: '/skill/skill-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Contact',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'contact-list',
          title: 'Contact List',
          type: 'item',
          url: '/contact/contact-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Subscribe',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'subscribe-list',
          title: 'Subscribe List',
          type: 'item',
          url: '/subscribe/subscribe-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Jobs',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'job-list',
          title: 'Jobs List',
          type: 'item',
          url: '/job/job-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Faqs',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'faq',
          title: 'Add Faq',
          type: 'item',
          url: '/faq/faq-form',
          target: false
        },
        {
          id: 'faq-list',
          title: 'Faq List',
          type: 'item',
          url: '/faq/faq-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Blogs',
      type: 'collapse',
      icon: icons.IconKey,
      children: [
        {
          id: 'blog-form',
          title: 'Add Blog',
          type: 'item',
          url: '/blog/blog-form',
          target: false
        },
        {
          id: 'blog-list',
          title: 'Blog List',
          type: 'item',
          url: '/blog/blog-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'JobSeeker',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'job-seeker-list',
          title: 'JobSeeker List',
          type: 'item',
          url: '/job-seeker/job-seeker-list',
          target: false
        }
      ]
    },
    {
      id: 'collapse',
      title: 'Employer',
      type: 'collapse',
      icon: icons.IconKey,

      children: [
        {
          id: 'employer-list',
          title: 'Employer List',
          type: 'item',
          url: '/employer/employer-list',
          target: false
        }
      ]
    }
  ]
};

export default pages;
